import React from "react"
import { Layout, Seo } from "../components"
import ConfirmReportSent from "../components/ConfirmReportSent"

const SentReportConfirmation = ({ location }) => {
  return (
    <>
      <Layout>
        <Seo title="Rapport envoyé" />
        <ConfirmReportSent location={location} />
      </Layout>
    </>
  )
}

export default SentReportConfirmation
