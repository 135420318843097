import React from "react"
import { navigate } from "gatsby"
import {
  Button,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"

const ConfirmReportSent = ({ location }) => {
  const theme = useTheme()
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Stack
      id="Report"
      sx={{
        backgroundPosition: "right",
        backgroundSize: mediumScreen ? "100%" : "contain",
        backgroundRepeat: "no-repeat",
        bgcolor: theme.palette.tertiary.main,
        mt: mediumScreen ? "100px" : 0,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        p: "0 4rem",
      }}
    >
      <Stack
        className="hero-section"
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          // height: "100vh",
          mt: mediumScreen ? 0 : "2rem",
        }}
      >
        <Typography
          component="h3"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBlack,
            fontSize: smScreen ? "25px" : mediumScreen ? "20px" : "28px",
            my: smScreen ? "2rem" : mediumScreen ? "5rem" : "2rem",
            width: mediumScreen ? "95%" : "70%",
          }}
        >
          Consulter votre boite e-mail
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: smScreen ? "14px" : mediumScreen ? "16px" : "18px",
            my: smScreen ? "0rem" : mediumScreen ? "-2rem" : "0rem",
            width: mediumScreen ? "100%" : "70%",
            mb: "1rem",
          }}
        >
          <p>
            Bonjour{" "}
            <span
              style={{
                color: "#D92950",
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {location.state.firstName}
            </span>
            ,{" "}
          </p>
          <p>
            Nous vous avons envoyé le Rapport State of dev 2021 à l'adresse{" "}
            <Link
              sx={{
                color: "#D92950",
                textDecoration: "none",
              }}
              href={`mailto:${location.state.requesterEmail}`}
            >
              {location.state.requesterEmail}
            </Link>
          </p>
        </Typography>
        <Button
          onClick={() => navigate("/")}
          variant="contained"
          color="secondary"
          sx={{
            px: mediumScreen ? "1.2rem" : "1.5rem",
            py: mediumScreen ? "0.3rem" : "0.5rem",
            mr: mediumScreen ? "1rem" : "2rem",
            textTransform: "uppercase",
            bgcolor: theme.palette.secondary.main,
            border: `2px solid ${theme.palette.secondary.main}`,
            "&:hover": {
              transition: 0.3,
              bgcolor: theme.palette.tertiary.main,
              "& p": {
                color: theme.palette.secondary.main,
              },
            },
            width: "250px",
          }}
        >
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: smScreen ? "12px" : mediumScreen ? "16px" : "18px",
              color: theme.palette.tertiary.main,
            }}
          >
            {"Revenir à l'accueil"}
          </Typography>
        </Button>
      </Stack>
    </Stack>
  )
}

export default ConfirmReportSent
